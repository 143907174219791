import React, { useEffect, useRef, useState } from 'react'
import { useCategorizePage } from '@utils/analytics/CategorizePage'
import useScript from '../../utils/useScript'
import getConfig from 'next/config'

import { useUserSession } from '@src/utils/useUserSession'
import { Fade } from '@material-ui/core'
import { ChatProps } from './'
import { useCurrentUser } from '@src/utils/useCurrentUser'
import { useGetChatConfigQuery } from '@src/queries/GetChatConfig.generated'
import { parseRegionalUrl } from '@src/utils/regional'
import { useRouter } from 'next/router'

const {
  publicRuntimeConfig: { einsteinChat },
} = getConfig()

export const EinsteinChat: React.FC<ChatProps> = ({
  productNumber,
  productName,
  channel,
  isAemPageChat = false,
}) => {
  const scriptStatus = useScript(einsteinChat?.baseCoreURL)
  const pageCategory = useCategorizePage()
  const { currentUser, userIsLoggedIn } = useCurrentUser()
  const { userSession } = useUserSession()
  const router = useRouter()
  const isClient = typeof window === 'object'
  const isEinsteinChatScriptReady =
    isClient && scriptStatus === 'ready' && !!window.embeddedservice_bootstrap
  const [initialized, setInitialized] = useState<boolean>(false)
  const [ipAddress, setIPAddress] = useState<boolean>(false)
  const chatEl = useRef<HTMLDivElement>(null)

  /**
   * initialize the embedded service chat once it is available.
   */
  const { data } = useGetChatConfigQuery({
    ssr: false,
    skip: !currentUser?.metadata.isChatEnabled,
  })

  //Find the array item with the matching chat channel since API gives us just an array of all chats.
  const chatConfig = data?.getChatConfig?.find(
    (item) => channel === item?.chatType
  )

  useEffect(() => {
    async function getIP() {
      try {
        const response = await fetch('https://api.ipify.org?format=json')
        const data = await response.json()
        setIPAddress(data.ip)
      } catch (error) {}
    }
    if (!ipAddress) getIP()

    if (!initialized && isEinsteinChatScriptReady && chatConfig && ipAddress) {
      try {
        window.embeddedservice_bootstrap.settings.language =
          userSession.language
        window.embeddedservice_bootstrap.settings.targetElement = chatEl.current
        window.addEventListener('onEmbeddedMessagingReady', () => {
          window.embeddedservice_bootstrap.prechatAPI.setHiddenPrechatFields({
            ProductCode: productNumber || '',
            Country: userSession.country,
            Language: userSession.language,
            PageType: pageCategory,
            ProductName: productName || '',
            url: window?.location.href.slice(0, 100),
            accessToken: userIsLoggedIn ? userSession.accessToken : '',
            ipaddress: ipAddress,
          })

          if (
            currentUser?.__typename === 'LoggedInUser' &&
            currentUser.firstName &&
            currentUser.lastName &&
            currentUser.email
          ) {
            window.embeddedservice_bootstrap.prechatAPI.setVisiblePrechatFields(
              {
                _firstName: {
                  value: currentUser.firstName,
                  isEditableByEndUser: true,
                },
                _lastName: {
                  value: currentUser.lastName,
                  isEditableByEndUser: true,
                },
                _email: {
                  value: currentUser.email,
                  isEditableByEndUser: true,
                },
              }
            )
          }
        })

        window.embeddedservice_bootstrap.init(
          einsteinChat?.initCode,
          einsteinChat?.initType,
          einsteinChat.initUrl,
          {
            scrt2URL: einsteinChat.scrt2URL,
          }
        )
        setInitialized(true)
      } catch (err) {
        console.error('Error loading Embedded Messaging: ', err)
      }
    }
    return () => {
      if (initialized) {
        /**
         * When the chat component is unmounted we clear all of the original salesforce scripts
         * This allows them to be reinitialized on any subsequent pages
         */
        document.querySelectorAll('script').forEach((i) => {
          i.src.includes('ESWMerck') && i.remove()
          i.src.includes('ESWMessaging') && i.remove()
        })

        // delete is not supported by ie11, instead we null the field rather than brining in polyfills
        // delete window.embedded_svc
        window.embeddedservice_bootstrap?.removeEventHandlers()
        window.embeddedservice_bootstrap = null
      }
    }
  }, [isEinsteinChatScriptReady, chatConfig, initialized, ipAddress])

  if (!currentUser?.metadata.isChatEnabled) return null

  //Don't render the chat widget if there isn't a route match after country/lang is stripped.
  const parsedUrl = parseRegionalUrl(router.asPath)
  if (
    !chatConfig?.routes.some((route) =>
      parsedUrl?.pathAfterBasename?.includes(route)
    ) &&
    !isAemPageChat
  ) {
    return null
  }

  return (
    <Fade in={initialized} timeout={{ enter: 5000 }}>
      <div ref={chatEl} id="chat-container" />
    </Fade>
  )
}
